<template>
  <div class="industry-reporter">
    <div class="fin-apps-logo row pb-3">
      <div class="col-sm-6">
        <img src="/logo.png" class="report-logo" />
      </div>
    </div>
    <b-card class="page-card" body-class="p-0">
      <div slot="header">
        <b-row>
          <b-col cols="6">
            <div class="page-header-left">
              <div class="pr-1 header-icon">
                <i class="cuis-industry card-icon-font"></i>
              </div>
              <div>
                <h5 class="mb-0">
                  <b>FIN Industry Reporter</b>
                  <i v-if="!isReportPage"> - {{ cardSubTitle }}</i>
                </h5>
              </div>
            </div>
          </b-col>

          <b-col cols="6" v-if="isReportPage && selectedReport">
            <div class="page-header-right">
              <div class="pr-2">
                <span class="h5 mb-0"> {{ selectedReport.name }}</span>
                <span
                  class="p-0 pb-1 pr-1 favorite__star__selected"
                  v-if="selectedReport.category === 'key_metrics'"
                  ><b> &#x2605;</b></span
                >
                <span v-if="showShared" class="pr-1">
                  <b-badge pill variant="secondary" class="px-1">
                    Shared
                  </b-badge>
                </span>
              </div>

              <div class="page-settings multi-col mt-2 align-items-center">
                <div
                  :class="[
                    'cuis-cloud-download font-xl pl-1 pr-1 cursor-pointer'
                  ]"
                  title="Download Report"
                  @click="downloadIndustryReport"
                  v-if="isMultiMetric || isMultiPeriod"
                ></div>
                <b-nav-item-dropdown
                  right
                  no-caret
                  class="print-pdf-dd d-flex px-1"
                >
                  <template slot="button-content">
                    <v-wait for="preparingPrint">
                      <template slot="waiting">
                        <i class="fa fa-lg fa-spinner fa-spin"></i>
                      </template>
                      <i
                        title="Print Report"
                        class="cuis-print d-block card-icon-font cursor-pointer"
                      ></i>
                    </v-wait>
                  </template>
                  <div>
                    <b-dropdown-item @click.prevent="printReport(false)">
                      Print Report
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click.prevent="printReport(true)"
                      v-if="isMultiMetric && isMultiPeriod"
                    >
                      Print Report with Charts
                    </b-dropdown-item>
                  </div>
                </b-nav-item-dropdown>
                <div
                  :class="['fa fa-cog fa-lg']"
                  @click.stop="showSettings"
                ></div>
              </div>
              <div
                :class="['fa fa-dot-circle-o pl-1 rerun-alert']"
                @click.stop="showSettings"
                v-if="reportOutdated"
                title="Cache Outdated"
              ></div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="row justify-content-center">
        <div class="col-sm-12">
          <router-view></router-view>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
// global
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'IndustryReporter',
  created() {
    if (!this.partnerSite) {
      document.title = 'FIN Industry Reporter'
    }
  },
  beforeDestroy() {
    this.resetIndustryReporterState()
  },
  computed: {
    ...mapState('IndustryReporter', {
      metricMode: state => state.metricMode,
      selectedScope: state => state.selectedScope,
      selectedReport: state => state.selectedReport,
      reportOutdated: state => state.reportOutdated
    }),
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    ...mapGetters(['showAside', 'isAsideOpen']),
    showShared() {
      return (
        this.selectedReport.category !== 'fin_reports' &&
        this.selectedReport.group_ids &&
        this.selectedReport.group_ids.length
      )
    },
    cardSubTitle() {
      switch (this.$route.name) {
        case 'IRSelectFIGroup':
          return 'Select FI Group'
        case 'IRSelectReport':
          return 'Select Report'
        case 'IRSegmentation':
          return 'Select Segmentation (Optional)'
        default:
          return ''
      }
    },
    isReportPage() {
      return this.$route.name === 'IRReport'
    },
    isMultiMetric() {
      return this.metricMode === 'multi'
    },
    isMultiPeriod() {
      return this.selectedScope === 'trend'
    }
  },
  methods: {
    ...mapActions('IndustryReporter', ['downloadReport']),
    ...mapMutations('IndustryReporter', [
      'resetIndustryReporterState',
      'setPrintWithSingleMetricsData'
    ]),
    printReport(withSingleMetrics) {
      this.setPrintWithSingleMetricsData(withSingleMetrics)

      if (withSingleMetrics) {
        this.$wait.start('preparingPrint')
        setTimeout(
          function() {
            this.$nextTick(() => {
              this.$toasted.clear()
              window.print()
              this.$wait.end('preparingPrint')
            })
          }.bind(this),
          250
        )
      } else {
        this.$nextTick(() => {
          this.$toasted.clear()
          window.print()
        })
      }
    },
    downloadIndustryReport() {
      this.downloadReport()
    },
    showSettings() {
      document.body.classList.remove('aside-menu-hidden')
      this.$store.dispatch('setAsideToogle', true)
    }
  }
}
</script>
<style lang="scss" src="../../advanced_query/scss/advanced.query.scss"></style>
<style lang="scss">
.print-pdf-dd {
  a.nav-link {
    padding: 0 0.25rem;
    color: #151b1e;
  }
  a.nav-link.disabled {
    color: #536c79;
  }
}

.fin-apps-logo {
  display: none;
}

.page-settings {
  display: flex;
}

@media print {
  .industry-reporter {
    .page-card {
      border: solid 3px #e4e5e6;
    }

    .fin-apps-logo {
      display: block;
    }

    .page-settings {
      display: none;
    }
  }
}

.industry-reporter {
  .rerun-alert {
    color: #dc3545;
  }
}
.favorite__star__selected {
  color: #a7ca77;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1rem;
  cursor: pointer;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
  -webkit-text-stroke: 0.6px grayscale($color: #686262fb);
}
</style>
